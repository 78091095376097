<template>
  <div>
    <v-list-item @click="selectItem" tile flat class="px-1"
                 :style="{ minHeight:'42px','background-color': (item === selectedItem ? 'rgba(63,106,216,0.45)' : (itemIndex % 2 === 1 ? this.$vuetify.theme.dark ? '#303030' : '#ededed' : ''))}">

      <v-list-item-content class=" py-0">
        <v-list-item-title>
          <v-row class="ma-0 mx-0" no-gutters>
            <v-col cols="12" md="12" sm="12"
                   :style="{'font-size': '14px', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}">
              <!-- Name -->
              <span
                  :style="{fontWeight: 'bold', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}">
                {{ item.isWeighted?1:item.amount }}x
              </span> <span class="text-wrap">{{ itemName }}</span> 
              <span v-if="showGFMin" class="text-wrap"><br>{{ $t('generic.lang_min') }} : {{
                item.min_redeem_cart_value|currency
              }}</span>
              <span class="text-wrap" v-if="this.depositGroup !== null"><br>{{
                this.depositGroup.name
              }}</span>
              <div class="error--text pt-1">{{ item.serialNo }}</div>
            </v-col>
          </v-row>
        </v-list-item-title>
      </v-list-item-content>
      <!-- ITEM PRICE-->
      <v-list-item-action class="ma-0 pa-0">
        <v-row align="center" class="ma-0 mx-0 pr-1" no-gutters>
          <v-col class="text-center" align-self="center" cols="12" md="12" sm="12">
        <span
            :style="{fontSize:'14px !important',fontFamily: 'Anton', float: 'right', 'text-decoration': ((item.isVoid === true || item.isVoid === 1) ? 'line-through': '')}">
                <template v-if="!item.isWeighted">
                    {{ itemSellPrice | currency }}
                  <span style="color: #3F6AD8;"></span>/
                </template>
                {{ itemStackSellPrice | currency }}

                <span v-if="item.discount > 0" style="color: #D92550;">({{ item.discount }}%)</span>
              </span>
          </v-col>
        </v-row>
      </v-list-item-action>
      <!-- ITEM FREETEXT-->
      <v-list-item-action v-if="item.freeText" class="ma-0 pa-0 ml-1"
                          style="background-color: #21D59B; margin-right: 2px !important;">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="text-center white--text">
              <font-awesome-icon :icon="['fal','comment-alt-lines']"/>
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>

      <v-list-item-action v-if="quickVoid && !item.isVoid" class="ma-0 pa-0 ml-1"
                          style="background-color: #FF6969; margin-right: 2px !important;" @click.stop="customerVoidItem">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="text-center white--text">
              <font-awesome-icon :icon="['fal','trash']"/>
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>

      <!-- ITEM COURSE-->
      <v-list-item-action v-if="item.course" class="ma-0 pa-0 ml-1"
                          style="background-color: #FF6969;margin-right: 2px !important;" @click="coursesDialog = !item.isVoid">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="text-center white--text">
              {{ item.course.position }}
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>
      <!-- ITEM EXTRAS-->
      <v-list-item-action v-if="item.selectedExtras && item.selectedExtras.length > 0" class="ma-0 pa-0 ml-1"
                          style="background-color: #F99600;margin-right: 2px !important;">
        <v-card elevation="0" class="mx-auto pa-0 py-2" tile color="transparent">
          <v-row no-gutters>
            <v-col cols="12" align="center" class="white--text">
              <font-awesome-icon :icon="['fal','pepper-hot']"/>
            </v-col>
          </v-row>
        </v-card>
      </v-list-item-action>
    </v-list-item>
    <v-list-item @click="selectItem" v-if="item.isPriceChanged" class="px-0"
                 :style="{minHeight:'20px','background-color':(this.$vuetify.theme.dark? '': (item === pos.retail.selectedItem ? 'rgba(63,106,216,0.45)' : (itemIndex % 2 === 1 ? '#ededed' : '')))}">
      <v-list-item-action class="pa-0 ma-0" style="width: 100% !important;">
        <v-row no-gutters>
          <v-col cols="12" align="end">
            <v-card dark elevation="0" class="pa-0 px-2 text-left rounded-br-xl"
                    style="font-size: 0.8em !important;width: fit-content;" color="error">
              {{$t('generic.lang_originalPrice')+" : "}} {{ item.originalSellPrice|currency }}
            </v-card>
          </v-col>
        </v-row>
      </v-list-item-action>
    </v-list-item>
    <template
        v-if="selectedItem!==null && item.selectedExtras && item.selectedExtras.length > 0 && selectedItem.randomPositionID === item.randomPositionID">
      <v-list-item class="pa-0"
                   :style="{minHeight:'30px', 'background-color':(this.$vuetify.theme.dark? '': (item === selectedItem ? 'rgba(63,106,216,0.45)' : (itemIndex % 2 === 1 ? '#ededed' : '')))}"
      >
        <v-list-item-content class="pa-0">
          <v-row justify="start" align-self="start" class="ma-0 mx-0" no-gutters>
            <v-col cols="12">
              <div v-if="true">
                <div class="text-wrap" style="font-size: 12px " v-for="(extra,i) in item.selectedExtras"
                     :key="extra.name+i">
                  &#9656; {{ extra.amount }}x {{ extra.name }} {{ extra.sellPrice | currency }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </template>
    <div style="height: 1px; border-top: 1px dashed #b4b4b4; "></div>
    <ButtonsCourseDialog :pos-type="posType" :show-dialog="coursesDialog" @closeDialog="coursesDialog = false"/>
    <ButtonsFreetextDialog @closeDialog="freeTextDialog = false" :pos-type="posType" :item="item"
                           :show-dialog="freeTextDialog"/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from '@fortawesome/fontawesome-svg-core';
import ButtonsFreetextDialog from "../buttons/ButtonsFreetextDialog"
import ButtonsCourseDialog from "../buttons/ButtonsCourseDialog"
import {roundNumber} from "@/plugins/round";
import {format} from 'mathjs';

import {
  faBarcode,
  faCheeseburger,
  faCocktail,
  faConciergeBell,
  faCroissant,
  faFish,
  faFrenchFries,
  faGifts,
  faHamburger,
  faHotdog,
  faMeat,
  faPersonCarry,
  faPizzaSlice,
  faSalad,
  faSandwich,
  faShishKebab,
  faSoup,
  faTaco,
  faTrash,
  faUtensilsAlt,
} from '@fortawesome/pro-light-svg-icons'
import {Events} from "@/plugins/events";

library.add(
    faHotdog,
    faBarcode,
    faCocktail,
    faPersonCarry,
    faMeat,
    faGifts,
    faConciergeBell,
    faHamburger,
    faCheeseburger,
    faFrenchFries,
    faPizzaSlice,
    faShishKebab,
    faFish,
    faCroissant,
    faSalad,
    faTaco,
    faSandwich,
    faSoup,
    faUtensilsAlt,
    faTrash
);


export default {
  name: "InvoiceOrderedItem",
  components: {
    ButtonsCourseDialog,
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsFreetextDialog,
  },
  props: {
    posType: String,
    item: Object,
    itemIndex: Number,
    quickVoid: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      showExtras: false,//TODO:
      freeTextDialog: false,
      coursesDialog: false,
    }
  },
  watch: {
    selectedItem(val) {
      if (val === null) {
        return;
      }
      if (typeof (this.item) === "undefined") {
        return;
      }
      if (this.item.id === val.id) {
        Events.$emit("itemSelected", 'booked_' + val.randomPositionID);
      }
    },
  },
  computed: {
    ...mapState([
      'pos',
      'mealsizes',
      'deposit'
    ]),
    showGFMin() {
      return this.item?.ean?.includes("GF$") && this.item?.hasOwnProperty('min_redeem_cart_value') && this.item.min_redeem_cart_value > 0;
    },
    selectedItem() {
      if (this.posType === "retail") {
        return this.pos.retail.selectedItem;
      }

      if (this.posType === "gastro") {
        return this.pos.gastro.selectedOrderedItem;
      }

      if (this.posType === "wholesaleRetail") {
        return this.pos.wholesaleRetail.selectedItem;
      }

      return null;
    },
    depositGroup() {
      if (this.item.depositgroupID === 0)
        return null;

      //FIND DEPOSIT GROUP
      let depositGroup = this.deposit.deposits.find((depositGroup) => {
        return depositGroup.id === this.item.depositgroupID;
      });

      if (!depositGroup)
        return null;

      return depositGroup;
    },
    itemName() {
      //CHECK IF ITEM HAS MEALSIZE ID
      if (this.item.mealSizeID > 0) {
        //FIND MEALSIZE
        let mealsize = this.mealsizes.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

        if (mealsize)
          return this.item.name + " " + mealsize.name;
      }

      return this.item.name;
    },
    itemSellPrice() {
      let sellPrice = this.item.sellPrice;
      if(this.posType === "wholesaleRetail"){
        sellPrice = this.item.netPrice;
      }

      // -----------------------------------------------------
      // --------------------- DISCOUNT -----------------------
      //Discount ONLY IN %
      if (this.item.sellPrice === this.item.originalSellPrice && this.item.discount > 0) {
        //sellPrice = ((100 - this.item.discount) / 100) * sellPrice;
        //sellPrice =  parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));

        sellPrice = Number(format(sellPrice, {precision: 14}));

      }

      //DISCOUNT WITH PRICE CHANGE
      if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount === 0) {
        sellPrice = 1 * sellPrice;
      }

      //DISCOUNT IN % AND PRICECHANGE
      if (this.item.sellPrice !== this.item.originalSellPrice && this.item.discount > 0) {
        //sellPrice = 1 * (((100 - this.item.discount) / 100) * sellPrice)
        //sellPrice =  parseFloat((Math.round(((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        sellPrice = parseFloat(((((sellPrice * ((100 - this.item.discount) / 100)) + Number.EPSILON) * 100) / 100));
        sellPrice = Number(format(sellPrice, {precision: 14}));
      }
      // -----------------------------------------------------

      //CHECK DEPOSIT
      if (this.depositGroup !== null) {
        sellPrice += this.depositGroup.priceTotal;
      }
      sellPrice = format(sellPrice, { precision: 14 });
      sellPrice = roundNumber(sellPrice, 2);
      return sellPrice;
    },
    itemStackSellPrice() {
      let weightCalc = 0.00;
      weightCalc = this.itemSellPrice * this.item.amount
      weightCalc = format(weightCalc, { precision: 14 });
      weightCalc = roundNumber(weightCalc, 2);
      return weightCalc;
    },
  },

  methods: {
    customerVoidItem(){
      this.$emit('customerVoidItem', this.item);
    },
    selectItem() {
      if (this.posType === "gastro") {
        if (this.pos.gastro.isDeliveryService) {
          if (!this.item.randomPositionID.includes('$deliveryCost')) {
            this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
          }
        } else {
          this.$store.dispatch("pos/gastro/selectInvoiceOrderedItem", this.item);
        }
      } else if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/selectInvoiceItem", this.item);
      } else if (this.posType === "wholesaleRetail") {
        this.$store.dispatch("pos/wholesaleRetail/selectInvoiceItem", this.item);
      }
    },
    getCourseIcon() {
      if (this.item.course.courseIcon === null || this.item.course.courseIcon === '') {
        return 'utensils-alt';
      } else {
        return this.item.course.courseIcon;
      }
    },

  }
}
</script>
